import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useCallback, useEffect, useState } from "react";
import "../styles/main.css";
import "../styles/App.css";
import { AppProps } from "next/app";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { createIDBPersister } from "@lib/IDBQueryPersister";
import StateInitializer from "@components/StateInitializer/StateInitializer";
import { IntercomProvider } from "react-use-intercom";
import posthog from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { useRouter } from "next/router";
import { AuthPayloadType, invalidateToken, useAuth } from "@lib/api-hooks";
import { TooltipProvider } from "@ui";
import { Space_Grotesk } from "next/font/google";
import { useAtom } from "jotai";
import { dashboardAccessAtom } from "atoms";
import { hasPayloadAccess } from "@api/auth/userInfo";
import { TracifyJWTPayload } from "@api/types/backendTypes";
import CurrencyModalProvider from "./providers/CurrencyModalProvider";
import { ThemeProvider } from "next-themes";
import { DEPLOY_ENVIRONMENT } from "constants/constants";
import "nprogress/nprogress.css";
import { Toaster } from "@components/ui/sonner";
import { toast } from "sonner";

// eslint-disable-next-line new-cap
const spaceGrotesk = Space_Grotesk({
  subsets: ["latin"],
  weight: ["400", "500", "600", "700"],
  display: "swap",
});

if (
  typeof window !== "undefined" &&
  !window.location.hostname.includes("localhost") &&
  DEPLOY_ENVIRONMENT !== "demo"
) {
  console.log("hello");
  posthog?.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
    // Next.js reverse proxy to avoid analytics blocking
    api_host: `${window.location.origin}/ingest` || "https://eu.posthog.com",
    session_recording: {
      recordCrossOriginIframes: true,
    },
    disable_session_recording:
      typeof window !== "undefined" &&
      window.location.hostname.includes("localhost"),
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog?.debug();
    },
  });
  (window as any).posthog = posthog;
}

if (process.env.NEXT_PUBLIC_API_MOCKING === "enabled") {
  require("../mocks");
}

const Noop: React.FC<any> = ({ children }) => {
  const { data } = useAuth();
  const posthog = usePostHog();

  useEffect(() => {
    if (data?.payload?.tid) {
      posthog?.identify(data.payload.tid, { email: data?.payload.ema });
    }
  }, [data?.payload?.tid, data?.payload?.ema, posthog]);
  return <>{children}</>;
};

let path = "/";

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [, setDashboardAccess] = useAtom(dashboardAccessAtom);
  useEffect(() => {
    // Track page views
    const handleRouteChange = (route: string) => {
      // only track pageviews when we change location
      // we don't care about query param changes here
      // because else we would spam our analytics with
      // every application state change that gets saved in the URL
      const pathname = route.split("?")[0] ?? "/";
      if (pathname !== path) {
        path = pathname;
        posthog?.capture("$pageview");
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const redirectOnError = useCallback(
    (redirect?: string) => {
      // don't redirect if we are already on the login page
      if ((redirect === "login" || !redirect) && router.pathname === "/login")
        return;
      // if we have a redirect set and the authentication request fails
      // we assume that we should redirect to the given route
      const nextPath = encodeURIComponent(window.location.href);
      window.location.href = `/${redirect ?? "login"}?next=${nextPath}`;

      // TODO fix atomWithQuery to not redirect so often, so we can use the router here
      // else next router will abort some route changes
      // router.replace(`/${redirect ?? "login"}?next=${nextPath}`);
    },
    [router]
  );

  const checkApprovals = useCallback(
    (data: AuthPayloadType | null, approvals?: string[]) => {
      if (!data?.payload) return setDashboardAccess(false);
      // all tracify users get access to the dashboard
      if (data.payload.ema.endsWith("@tracify.ai")) {
        return setDashboardAccess(true);
      }

      if (approvals && approvals.length > 0) {
        // check if the user payload has the passed through approvals
        let totalAccess = true;
        for (const approval of approvals) {
          const access = hasPayloadAccess(
            data.payload as TracifyJWTPayload,
            approval
          );
          if (!access) {
            totalAccess = false;
            break;
          }
        }
        setDashboardAccess(totalAccess);
      } else if (approvals && approvals.length === 0) {
        // we assume that the user does not need any approvals here
        setDashboardAccess(true);
      }
    },
    [setDashboardAccess]
  );

  // run this to initialize all atoms to prefill state from URL
  const Layout = (Component as any).Layout || Noop;
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 5 * 1000,
            gcTime: 5 * 60 * 1000, // 5 minutes
          },
        },
        queryCache: new QueryCache({
          onError: (error, query) => {
            if (query.queryKey.includes("auth")) {
              invalidateToken();
              redirectOnError(query?.meta?.redirect as string | undefined);
            } else if (query.queryKey.includes("analytics")) {
              console.log({ error });
              if (
                error.message ===
                "Invalid token provided! Please provide a valid kytron token."
              ) {
                invalidateToken();
              } else if (
                error.message.includes("failed with status code 429") ||
                error.message
                  .toLowerCase()
                  .includes("too many analytics jobs placed")
              ) {
                toast.error(
                  "We couldn't load your data because you had too many concurrent requests. Please try again in a few minutes.",
                  { id: "analytics_error_rate_limit" }
                );
              } else if (
                (error as any).response?.data?.error
                  ?.toLowerCase()
                  .includes("Invalid request range!") ||
                error.message.includes("Invalid request range!")
              ) {
                toast.error(
                  "The timerange you selected is too long. Please try again.",
                  { id: "analytics_error_timerange" }
                );
              } else if (
                error.message.includes("Not allowed to read events for csid")
              ) {
                toast.error(
                  "You're not allowed to read data for the selected store. Please choose a different one. ",
                  { id: "analytics_error_not_allowed" }
                );
              } else {
                console.log({ error: error.message });
                toast.error("An error has occurred. Please try again.", {
                  id: "analytics_error_general",
                });
              }
            } else if (
              error.message ===
              "Invalid token provided! Please provide a valid kytron token."
            ) {
              invalidateToken();
            }
          },
          onSuccess: (data, query) => {
            if (query.queryKey.includes("auth")) {
              checkApprovals(
                data as AuthPayloadType | null,
                query?.meta?.approvals as string[] | undefined
              );
            }
          },
        }),
      })
  );

  useEffect(() => {
    const idbPersister = createIDBPersister("tracify_cache", 10000);
    const [unsubscribe] = persistQueryClient({
      queryClient,
      persister: idbPersister,
      maxAge: 5 * 60 * 1000, // 5 minutes
    });
    return () => {
      unsubscribe();
    };
  }, [queryClient]);

  return (
    <div className={`${spaceGrotesk.className}`}>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>
          <StateInitializer>
            <IntercomProvider
              appId={"bq1t6ztc"}
              apiBase="https://api-iam.eu.intercom.io"
            >
              <ThemeProvider attribute="class" defaultTheme="dark" enableSystem>
                <TooltipProvider>
                  <Layout pageProps={pageProps}>
                    <>
                      <Component {...pageProps} />
                      <CurrencyModalProvider />
                      <Toaster />
                    </>
                  </Layout>
                </TooltipProvider>
              </ThemeProvider>
            </IntercomProvider>
          </StateInitializer>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </PostHogProvider>
    </div>
  );
}
