import { clsx, type ClassValue } from "clsx";
import dayjs from "dayjs";
import { useTheme } from "next-themes";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(input: string | number): string {
  const date = new Date(input);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function absoluteUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_APP_URL}${path}`;
}

export function useLogo() {
  const { resolvedTheme } = useTheme();

  const logoPerTheme: Record<string, string> = {
    light: "/logo500x84_white.png",
    dark: "/logo500x84.png",
    system: "/logo500x84.png",
  };

  return logoPerTheme[(resolvedTheme ?? "dark") as keyof typeof logoPerTheme];
}

export const formatToBaseTracifyDate = (dateString: string) => {
  // Handle MM/YYYY format
  if (/^\d{2}\/\d{4}$/.test(dateString)) {
    const [month, year] = dateString.split("/");
    return `${month}-${year}`;
  }

  // Handle DD/MM/YYYY format
  if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
    const [day, month, year] = dateString.split("/");
    return `${day}-${month}-${year}`;
  }

  // For other formats, use dayjs parsing
  const date = dayjs(
    dateString,
    ["YYYY-MM-DD", "DD-MM-YYYY", "MM/DD/YYYY"],
    true
  );

  if (date.isValid()) {
    return date.format("DD-MM-YYYY");
  }

  return dateString;
};
